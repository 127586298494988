<template>
  <v-card class="card-shadow border-radius-xl">
    <div class="px-6 py-6">
      <h5 class="text-h5 font-weight-bold text-typo">{{ $t("transcript.ownerData") }}</h5>
    </div>
    <div class="px-6 pb-6 pt-0">
      <v-select
        v-model="selectedRegno"
        :items="regnoItems"
        item-text="text"
        item-value="value"
        label="登記次序"
        outlined
        dense
        class="mb-4"
      ></v-select>

      <v-row v-if="selectedOwner">
        <v-col cols="4">
          <label class="text-xs text-typo font-weight-bolder ms-1">{{ $t("city") }}</label>
          <v-text-field
            v-model="selectedOwner.縣市"
            hide-details
            outlined
            readonly
            class="font-size-input placeholder-darker border border-radius-md mt-2"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <label class="text-xs text-typo font-weight-bolder ms-1">{{ $t("district") }}</label>
          <v-text-field
            v-model="selectedOwner.鄉鎮市區"
            hide-details
            outlined
            readonly
            class="font-size-input placeholder-darker border border-radius-md mt-2"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <label class="text-xs text-typo font-weight-bolder ms-1">{{ $t("region") }}</label>
          <v-text-field
            v-model="selectedOwner.地段"
            hide-details
            outlined
            readonly
            class="font-size-input placeholder-darker border border-radius-md mt-2"
          ></v-text-field>
        </v-col>

        <v-col cols="4">
          <label class="text-xs text-typo font-weight-bolder ms-1">登記次序</label>
          <v-text-field
            v-model="selectedOwner.登記次序"
            hide-details
            outlined
            readonly
            class="font-size-input placeholder-darker border border-radius-md mt-2"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <label class="text-xs text-typo font-weight-bolder ms-1">登記日期</label>
          <v-text-field
            v-model="selectedOwner.登記日期"
            hide-details
            outlined
            readonly
            class="font-size-input placeholder-darker border border-radius-md mt-2"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <label class="text-xs text-typo font-weight-bolder ms-1">登記原因</label>
          <v-text-field
            v-model="selectedOwner.登記原因"
            hide-details
            outlined
            readonly
            class="font-size-input placeholder-darker border border-radius-md mt-2"
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <label class="text-xs text-typo font-weight-bolder ms-1">所有權人姓名</label>
          <v-text-field
            v-model="selectedOwner.所有權人姓名"
            hide-details
            outlined
            readonly
            class="font-size-input placeholder-darker border border-radius-md mt-2"
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <label class="text-xs text-typo font-weight-bolder ms-1">住址</label>
          <v-text-field
            v-model="selectedOwner.住址"
            hide-details
            outlined
            readonly
            class="font-size-input placeholder-darker border border-radius-md mt-2"
          ></v-text-field>
        </v-col>

        <v-col cols="6">
          <label class="text-xs text-typo font-weight-bolder ms-1">統一編號</label>
          <v-text-field
            v-model="selectedOwner.統一編號"
            hide-details
            outlined
            readonly
            class="font-size-input placeholder-darker border border-radius-md mt-2"
          ></v-text-field>
        </v-col>

        <v-col cols="6">
          <label class="text-xs text-typo font-weight-bolder ms-1">權利範圍</label>
          <v-text-field
            v-model="selectedOwner.權利範圍"
            hide-details
            outlined
            readonly
            class="font-size-input placeholder-darker border border-radius-md mt-2"
          ></v-text-field>
        </v-col>

        <v-col cols="6">
          <label class="text-xs text-typo font-weight-bolder ms-1">權狀字號</label>
          <v-text-field
            v-model="selectedOwner.權狀字號"
            hide-details
            outlined
            readonly
            class="font-size-input placeholder-darker border border-radius-md mt-2"
          ></v-text-field>
        </v-col>

        <v-col cols="6">
          <label class="text-xs text-typo font-weight-bolder ms-1">當期申報地價</label>
          <v-text-field
            v-model="selectedOwner.當期申報地價"
            hide-details
            outlined
            readonly
            class="font-size-input placeholder-darker border border-radius-md mt-2"
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <label class="text-xs text-typo font-weight-bolder ms-1">其他登記事項</label>
          <v-textarea
            v-model="selectedOwner.其他登記事項"
            hide-details
            outlined
            readonly
            auto-grow
            rows="2"
            class="font-size-input placeholder-darker border border-radius-md mt-2"
          ></v-textarea>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "owner-info",
  props: {
    ownerData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedRegno: null,
    };
  },
  computed: {
    regnoItems() {
      return this.ownerData.map(owner => ({
        text: `登記次序 ${owner.regno}`,
        value: owner.regno
      }));
    },
    selectedOwner() {
      if (!this.selectedRegno && this.ownerData.length > 0) {
        this.selectedRegno = this.ownerData[0].regno;
      }
      return this.ownerData.find(owner => owner.regno === this.selectedRegno);
    },
  },
  methods: {
    clearAddress(addr) {
      if(addr == null || addr == undefined) {
        return '';
      }
      const normalizedStr = addr.normalize('NFKC');
      return normalizedStr;
    },
  },
  watch: {
    ownerData: {
      immediate: true,
      handler(newVal) {
        if (newVal && newVal.length > 0) {
          this.selectedRegno = newVal[0].regno;
        }
      },
    },
  },
};
</script>
