<template>
  <v-dialog
    v-model="dialog"
    content-class="position-absolute top-0"
    width="95%"
  >
    <v-card>
      <v-card-actions class="justify-end">
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-actions>
      <v-tabs v-model="activeTab" background-color="transparent" grow>
        <v-tab>標示部</v-tab>
        <v-tab>所有權部</v-tab>
        <v-tab>他項權利部</v-tab>
      </v-tabs>

      <v-tabs-items v-model="activeTab">
        <v-tab-item>
          <mark-info :markData="tpData.mark" :lbtype="lbtype"></mark-info>
        </v-tab-item>
        <v-tab-item>
          <owner-info :ownerData="tpData.o_data"></owner-info>
        </v-tab-item>
        <v-tab-item>
          <creditor-info :creditorData="tpData.c_data"></creditor-info>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-dialog>
</template>

<script>
import MarkInfo from "./MarkInfo.vue";
import OwnerInfo from "./OwnerInfo.vue";
import CreditorInfo from "./CreditorInfo.vue";
export default {
  name: "TranscriptDialog",
  props: {
    show: {
      type: Boolean,
      required: true
    },
    tpData: {
      type: Object,
      required: true
    },
    lbtype: {
      type: String,
      required: true
    }
  },
  components: {
    MarkInfo,
    OwnerInfo,
    CreditorInfo
  },
  data() {
    return {
      activeTab: 0
    };
  },
  computed: {
    dialog: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit('update:show', value);
      }
    }
  }
};
</script>
