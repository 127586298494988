<template>
  <v-card class="card-shadow border-radius-xl">
    <div class="px-6 py-6">
      <h5 class="text-h5 font-weight-bold text-typo">{{ $t("transcript.creditorData") }}</h5>
    </div>
    <div class="px-6 pb-6 pt-0">
      <v-select
        v-model="selectedRegno"
        :items="regnoItems"
        item-text="text"
        item-value="value"
        label="登記次序"
        outlined
        dense
        class="mb-4"
      ></v-select>

      <v-row v-if="selectedCreditor">
        <v-col cols="4">
          <label class="text-xs text-typo font-weight-bolder ms-1">{{ $t("city") }}</label>
          <v-text-field
            v-model="selectedCreditor.縣市"
            hide-details
            outlined
            readonly
            class="font-size-input placeholder-darker border border-radius-md mt-2"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <label class="text-xs text-typo font-weight-bolder ms-1">{{ $t("district") }}</label>
          <v-text-field
            v-model="selectedCreditor.鄉鎮市區"
            hide-details
            outlined
            readonly
            class="font-size-input placeholder-darker border border-radius-md mt-2"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <label class="text-xs text-typo font-weight-bolder ms-1">{{ $t("region") }}</label>
          <v-text-field
            v-model="selectedCreditor.地段"
            hide-details
            outlined
            readonly
            class="font-size-input placeholder-darker border border-radius-md mt-2"
          ></v-text-field>
        </v-col>

        <v-col cols="6">
          <label class="text-xs text-typo font-weight-bolder ms-1">登記次序</label>
          <v-text-field
            v-model="selectedCreditor.登記次序"
            hide-details
            outlined
            readonly
            class="font-size-input placeholder-darker border border-radius-md mt-2"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <label class="text-xs text-typo font-weight-bolder ms-1">登記日期</label>
          <v-text-field
            v-model="selectedCreditor.登記日期"
            hide-details
            outlined
            readonly
            class="font-size-input placeholder-darker border border-radius-md mt-2"
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <label class="text-xs text-typo font-weight-bolder ms-1">權利人姓名</label>
          <v-text-field
            v-model="selectedCreditor.權利人姓名"
            hide-details
            outlined
            readonly
            class="font-size-input placeholder-darker border border-radius-md mt-2"
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <label class="text-xs text-typo font-weight-bolder ms-1">住址</label>
          <v-text-field
            v-model="selectedCreditor.住址"
            hide-details
            outlined
            readonly
            class="font-size-input placeholder-darker border border-radius-md mt-2"
          ></v-text-field>
        </v-col>

        <v-col cols="6">
          <label class="text-xs text-typo font-weight-bolder ms-1">權利人統一編號</label>
          <v-text-field
            v-model="selectedCreditor.權利人統一編號"
            hide-details
            outlined
            readonly
            class="font-size-input placeholder-darker border border-radius-md mt-2"
          ></v-text-field>
        </v-col>

        <v-col cols="6">
          <label class="text-xs text-typo font-weight-bolder ms-1">權利種類</label>
          <v-text-field
            v-model="selectedCreditor.權利種類"
            hide-details
            outlined
            readonly
            class="font-size-input placeholder-darker border border-radius-md mt-2"
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <label class="text-xs text-typo font-weight-bolder ms-1">擔保債權總金額</label>
          <v-text-field
            v-model="selectedCreditor.擔保債權總金額"
            hide-details
            outlined
            readonly
            class="font-size-input placeholder-darker border border-radius-md mt-2"
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <label class="text-xs text-typo font-weight-bolder ms-1">擔保債權種類及範圍</label>
          <v-textarea
            v-model="selectedCreditor.擔保債權種類及範圍"
            hide-details
            outlined
            readonly
            auto-grow
            rows="3"
            class="font-size-input placeholder-darker border border-radius-md mt-2"
          ></v-textarea>
        </v-col>

        <v-col cols="6">
          <label class="text-xs text-typo font-weight-bolder ms-1">設定權利範圍</label>
          <v-text-field
            v-model="selectedCreditor.設定權利範圍"
            hide-details
            outlined
            readonly
            class="font-size-input placeholder-darker border border-radius-md mt-2"
          ></v-text-field>
        </v-col>

        <v-col cols="6">
          <label class="text-xs text-typo font-weight-bolder ms-1">擔保債權確定期日</label>
          <v-text-field
            v-model="selectedCreditor.擔保債權確定期日"
            hide-details
            outlined
            readonly
            class="font-size-input placeholder-darker border border-radius-md mt-2"
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <label class="text-xs text-typo font-weight-bolder ms-1">共同擔保地號</label>
          <v-chip-group column>
            <v-chip
              v-for="item in selectedCreditor.共同擔保地號"
              :key="item.key"
              small
              class="ma-1"
            >
              {{ item.key }}
            </v-chip>
          </v-chip-group>
        </v-col>

        <v-col cols="12">
          <label class="text-xs text-typo font-weight-bolder ms-1">共同擔保建號</label>
          <v-chip-group column>
            <v-chip
              v-for="item in selectedCreditor.共同擔保建號"
              :key="item.key"
              small
              class="ma-1"
            >
              {{ item.key }}
            </v-chip>
          </v-chip-group>
        </v-col>

        <v-col cols="12">
          <label class="text-xs text-typo font-weight-bolder ms-1">其他登記事項</label>
          <v-textarea
            v-model="selectedCreditor.其他登記事項"
            hide-details
            outlined
            readonly
            auto-grow
            rows="2"
            class="font-size-input placeholder-darker border border-radius-md mt-2"
          ></v-textarea>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "creditor-info",
  props: {
    creditorData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedRegno: null,
    };
  },
  computed: {
    regnoItems() {
      return this.creditorData.map(creditor => ({
        text: `登記次序 ${creditor.regno}`,
        value: creditor.regno
      }));
    },
    selectedCreditor() {
      if (!this.selectedRegno && this.creditorData.length > 0) {
        this.selectedRegno = this.creditorData[0].regno;
      }
      return this.creditorData.find(creditor => creditor.regno === this.selectedRegno);
    },
  },
  watch: {
    creditorData: {
      immediate: true,
      handler(newVal) {
        if (newVal && newVal.length > 0) {
          this.selectedRegno = newVal[0].regno;
        }
      },
    },
  },
};
</script>
